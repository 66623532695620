import React, { useContext } from 'react';
import { AiOutlinePhone } from '@react-icons/all-files/ai/AiOutlinePhone';
import { AiOutlineMail } from '@react-icons/all-files/ai/AiOutlineMail';
import styled from 'styled-components';
import { CompanyNameContext } from '../layouts';
import { mediaBreakpoints } from '../lib/mediaBreakpoints';
import { companyTel, telFormatter } from '../lib/companyTel';
import {companyEmail} from '../lib/companyEmail'

const Footer = (): JSX.Element => {
  const companyName = useContext(CompanyNameContext);
  const startYear: number = 2023;
  const currentYear: number = new Date().getFullYear();
  const copyrightYearText: string = currentYear - startYear > 0 ? `${startYear} - ${currentYear}` : `${currentYear}`;
  return (
    <StyledFooter>
      <StyledP>
        &nbsp;Email :&nbsp;&nbsp;
        <AiOutlineMail aria-label='email icon' />
        <StyledA href={`mailto:${companyEmail}`} aria-label='Email address'>
          {companyEmail}
        </StyledA>
      </StyledP>
      <StyledP>
        &nbsp;Phone :&nbsp;&nbsp;
        <AiOutlinePhone aria-label='telephone icon' />
        <StyledA href={`tel:${companyTel}`} aria-label='Telephone number'>
          {telFormatter(companyTel)}
        </StyledA>
      </StyledP>
      <br />
      <StyledLegalP>
        &copy; {copyrightYearText} {companyName}, LLC
      </StyledLegalP>
      <StyledLegalP>All Rights Reserved</StyledLegalP>
    </StyledFooter>
  );
};

const StyledFooter = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--blue_two);
  border-image-slice: 1;
  padding: 1rem;
  font-size: 1rem;
  color: var(--white);
  & p {
    text-align: center;
  }
`;

const StyledLegalP = styled.p`
  font-size: calc(var(--font-size) * 0.7);
`;

const StyledP = styled.p`
  color: var(--white);
  display: flex;
  align-items: center;
  margin-bottom: calc(var(--margin) * 0.5);
  @media (max-width: ${mediaBreakpoints.sm}) {
    flex-direction: column;
  }
`;

export const StyledA = styled.a`
  color: var(--white);
  background-image: linear-gradient(to right, var(--white), var(--white));
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: 100% 7%;
  text-decoration: none;
  transition: background-size var(--transition-time) ease;
  :hover {
    background-size: 100% 15%;
  }
`;

export default Footer;
