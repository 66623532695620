import React from 'react';
import styled, { keyframes } from 'styled-components';

const Loader = () => {
  return (
    <StyledDiv tabIndex={-1}>
      <StyledSvg viewBox='0 0 50 50'>
        <StyledCircle cx='25' cy='25' r='20' fill='none' stroke-width='10'></StyledCircle>
      </StyledSvg>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  border: 1px solid orange;
  position: absolute;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  background: var(--white);
`;

const dash = keyframes`
0% {
  stroke-dasharray: 1, 150;
  stroke-dashoffset: 0;
}
50% {
  stroke-dasharray: 90, 150;
  stroke-dashoffset: -35;
}
100% {
  stroke-dasharray: 90, 150;
  stroke-dashoffset: -124;
}
`;

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const StyledSvg = styled.svg`
  animation: ${rotate} 3s ease infinite;
  z-index: 1000;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;
`;

const StyledCircle = styled.circle`
  stroke: var(--blue_one);
  stroke-linecap: round;
  animation: ${dash} 3s ease infinite;
`;

export default Loader;
