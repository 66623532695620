import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'gatsby';
import LogoContainer from './LogoContainer';
import styled from 'styled-components';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { AiOutlineMenu } from '@react-icons/all-files/ai/AiOutlineMenu';
import { AiOutlineClose } from '@react-icons/all-files/ai/AiOutlineClose';
import { mediaBreakpoints } from '../lib/mediaBreakpoints';
import { useOnClickOutside } from '../hooks/useOnClickOutside';

export interface INavMenuItem {
  path: string;
  title: string;
  visible: boolean;
  active: boolean;
}

interface INavProps {
  navMenuLinks: INavMenuItem[];
}

const iconStyles = {
  width: `60px`,
  height: `60px`,
};

const Nav = ({ navMenuLinks }: INavProps): JSX.Element => {
  const home = navMenuLinks.find((el: INavMenuItem) => el.path === '/');
  const ref = useRef<HTMLUListElement | null>(null);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  useOnClickOutside(ref, () => setIsMobileNavOpen(false));
  const breakpoints = useBreakpoint();
  useEffect(() => {
    if (!breakpoints.sm && !breakpoints.md) {
      setIsMobileNavOpen(false);
    }
  }, [breakpoints]);
  return (
    <StyledHeader>
      <StyledNav>
        <LogoContainer path={home!.path} title={home!.title} />
        {breakpoints.md ? (
          <StyledDiv>
            <StyledButton className='toggle' onClick={() => setIsMobileNavOpen((prev) => !prev)}>
              {isMobileNavOpen ? (
                <AiOutlineClose aria-label='close menu icon' style={iconStyles} />
              ) : (
                <AiOutlineMenu style={iconStyles} aria-label='open menu icon' />
              )}
            </StyledButton>
            <StyledUl ref={ref} show={isMobileNavOpen ? true : false}>
              {navMenuLinks
                .filter((navMenuItem: INavMenuItem) => navMenuItem.visible && navMenuItem.path !== home!.path)
                .map((navMenuItem: INavMenuItem) => (
                  <StyledLi key={navMenuItem.path}>
                    <StyledLink
                      onClick={() => setIsMobileNavOpen(false)}
                      activeClassName='active'
                      to={navMenuItem.path}
                      title={navMenuItem.title}
                      aria-label={navMenuItem.title}
                    >
                      {navMenuItem.title}
                    </StyledLink>
                  </StyledLi>
                ))}
            </StyledUl>
          </StyledDiv>
        ) : (
          <StyledUl>
            {navMenuLinks
              .filter((navMenuItem: INavMenuItem) => navMenuItem.visible && navMenuItem.path !== home!.path)
              .map((navMenuItem: INavMenuItem) => (
                <StyledLi key={navMenuItem.path}>
                  <StyledLink activeClassName='active' to={navMenuItem.path} title={navMenuItem.title} aria-label={navMenuItem.title}>
                    {navMenuItem.title}
                  </StyledLink>
                </StyledLi>
              ))}
          </StyledUl>
        )}
      </StyledNav>
    </StyledHeader>
  );
};

const StyledHeader = styled.header`
  border-bottom: 2rem solid var(--blue_two);
  border-bottom: 2rem solid transparent;
  border-image: linear-gradient(to bottom, var(--blue_two) 99%, var(--blue_one) 100%);
  border-image-slice: 30%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background: var(--white);
  padding: var(--padding);
`;

const StyledDiv = styled.div`
  position: relative;
`;

const StyledButton = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
`;

const StyledNav = styled.nav`
  display: flex;
  height: var(--logoHeight);
  align-items: center;
  justify-content: space-between;
  padding: 0rem 0.5rem;
  @media (max-width: ${mediaBreakpoints.l}) {
    height: auto;
    flex-direction: column;
    justify-content: center;
  }
`;

const StyledUl = styled.ul<{ show?: boolean }>`
  display: flex;
  list-style: none;
  font-size: var(--nav-font-size);
  flex-grow: 1.5;
  gap: 3rem;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: ${mediaBreakpoints.md}) {
    list-style: none;
    position: absolute;
    background: var(--white_transparent);
    left: 0;
    overflow: hidden;
    z-index: 9;
    box-shadow: 0 10px 15px -3px var(--gray), 0 4px 6px -2px var(--blue_one);
    transform: translateX(-900px);
    transition: transform ease var(--transition-time);
    gap: 0.5rem;
    flex-wrap: wrap;
    ${(props) => (props.show ? ` transform: translateX(0px);` : `transition: none`)}
  }
`;

const StyledLi = styled.li`
  display: flex;
  align-items: center;
  height: var(--li-height);
  @media (max-width: ${mediaBreakpoints.md}) {
    background: var(--white);
    border: 1px solid var(--blue_one);
    padding: var(--padding);
    width: 100%;
  }
`;

const StyledLink = styled(Link)`
  white-space: nowrap;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.2rem;
  height: 100%;
  width: 100%;
  text-decoration: none;
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    border-radius: 4px;
    background-color: var(--blue_two);
    bottom: 25%;
    left: 0;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform var(--transition-time) ease;
  }
  &:hover::before {
    background-size: 100% 10%;
    transform-origin: left;
    transform: scaleX(1);
  }
  &.active::before {
    background-size: 100% 10%;
    transform-origin: left;
    transform: scaleX(1);
  }
  @media (max-width: ${mediaBreakpoints.md}) {
    &::before {
      bottom: 4%;
    }
  }
`;

export default Nav;
