import React, { useContext } from 'react';
import { INavMenuItem } from './Nav';
import { Link } from 'gatsby';
import logo from '../images/logo_name.svg';
import styled from 'styled-components';
import { CompanyNameContext } from '../layouts';

const LogoContainer = ({ path, title }: Partial<INavMenuItem>): JSX.Element => {
  const companyName = useContext(CompanyNameContext);
  return (
    <StyledH1>
      <Link to={path!} title={title!} aria-label={`${title} home page`}>
        <StyledImg src={logo} alt={`${companyName} company logo`} />
      </Link>
    </StyledH1>
  );
};

const StyledImg = styled.img`
  object-fit: contain;
  height: var(--logoHeight);
  ::selection {
    color: transparent;
    background: transparent;
  }
  `;

const StyledH1 = styled.h1`
height: var(--logoHeight);
`;

export default LogoContainer;
