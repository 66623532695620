import React from 'react';
import Layout from './src/layouts/';
import { GlobalStyle } from './src/components/GlobalStyle';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';

export const wrapPageElement = ({ element, props }) => {
    return (
      <>
        <GlobalStyle />
        <Layout {...props}>{element}</Layout>
      </>
    );

};
