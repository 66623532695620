import { companyName} from './companyName'

export const navMenu = {
  navMenuLinks: [
    {
      path: '/',
      title: `${companyName}`,
      visible:true,
      active:false
    },
    {
      path: '/about/',
      title: 'About Us',
      visible:true,
      active:false
    },
    {
      path: '/products/',
      title: 'Products',
      visible:true,
      active:false
    },
    {
      path: '/contact/',
      title: 'Contact Us',
      visible:true,
      active:false
    },
    {
      path: '/success/',
      title: 'Message Sent',
      visible:false,
      active:false
    },
    // 404 object must be the last one in array
    {
      path: '',
      title: 'Page Not Found',
      visible:false,
      active:false
    },
  ],
};
