import { createGlobalStyle } from 'styled-components';
import { mediaBreakpoints } from '../lib/mediaBreakpoints';

export const GlobalStyle = createGlobalStyle`
:root {
  --blue_one: rgb(32, 48, 122);
  --blue_one_transparent: rgba(32, 48, 122,0.5);
  --blue_two: rgb(1, 123, 196);
  --blue_two_transparent: rgba(1, 123, 196,0.5);
  --red: rgb(255, 48, 21);
  --white: rgb(255, 255, 255);
  --white_transparent: rgba(255, 255,255, 0.75);
  --black:rgb(0,0,0);
  --black_transparent:rgba(0,0,0,.75);
  --gray: rgb(240, 240, 240);
  --dark_gray: rgb(70,70,70);
  --logoHeight: 5rem;
  --li-height:4rem;
  --margin:2rem;
  --padding:1rem;
  --border-radius:10px;
  --main-margin-top: calc(var(--logoHeight) + var(--margin) + var(--padding) * 2);
  --font-size:20px;
  --h1-size:2.5rem;
  --h2-size:2.25rem;
  --h3-size:2rem;
  --h4-size:1.75rem;
  --h5-size:1.5rem;
  --h6-size:1.25rem;
  --nav-font-size:1.2rem;
  --transition-time:0.3s;
  --max-width: ${mediaBreakpoints.l};
  --max-width-breakpoint-one: ${mediaBreakpoints.md};
  --max-width-breakpoint-two: ${mediaBreakpoints.sm};
  --max-pic-width:calc(${mediaBreakpoints.l} * 0.7);
  --scrollbar:16px;
}

::selection {
  color: var(--white);
  background: var(--blue_one);
}

*, *::before, *::after {
  margin: 0;
  padding:0;
  box-sizing: border-box;
  font:inherit;
  font-size:inherit;
}

html,body {
  height:100%;
  min-height:100%;
  font-family: Montserrat, Segoe UI, Roboto, Oxygen, Cantarell, Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: var(--font-size);
  font-weight: 400;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  @media (max-width: ${mediaBreakpoints.md}) {
      font-size: calc(var(--font-size) * 0.8);
      --max-pic-width: calc(${mediaBreakpoints.md} * 0.7);
      --max-width: ${mediaBreakpoints.md} ;
  }
    @media (max-width: ${mediaBreakpoints.sm}) {
      --max-pic-width: calc(${mediaBreakpoints.sm} * 0.7);
      --max-width: ${mediaBreakpoints.sm} ;
  }
      @media (max-width: ${mediaBreakpoints.xs}) {
      --max-pic-width: calc(${mediaBreakpoints.xs} * 0.9);
      --max-width: ${mediaBreakpoints.xs} ;
  }
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

#gatsby-focus-wrapper,
#___gatsby {
height: 100%;
width: 100%;
}

::-webkit-scrollbar {
  width: var(--scrollbar);
  height:var(--scrollbar);
}

::-webkit-scrollbar-track {
  background: var(--black);
}

::-webkit-scrollbar-thumb {
  background: var(--blue_two);
  border-radius:8px;
  border: 3px solid var(--black);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--blue_two);
}

::-webkit-scrollbar-corner{
  background: var(--black);
}

.container{
  opacity:1;
  transition:opacity 2s ease-in-out;
  height:100%;
  min-height:100%;
  display:flex;
  flex-direction:column;
}

a{
  color:inherit;
}

.container.loading{
  opacity:0;
  overflow:hidden;
}

.hidden{
  display:none;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

h1{
  font-size:var(--h1-size);
}

h2{
  font-size:var(--h2-size);
  margin:var(--margin) 0;
}

h3{
  font-size:var(--h3-size);
  margin-bottom:calc(var(--margin) * .5);
}

h4{
  font-size:var(--h4-size);
  margin:var(--margin) 0;
}

h5{
  font-size:var(--h5-size);
  margin:var(--margin) 0;
}

h6{
  font-size:var(--h6-size);
  margin:var(--margin) 0;
}
`;
