import React, { ReactNode, createContext, useState, useEffect } from 'react';
import Nav from '../components/Nav';
import { navMenu } from '../lib/navMenu';
import { companyName } from '../lib/companyName';
import Footer from '../components/Footer';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import Loader from '../components/Loader';

const { navMenuLinks } = navMenu;
export const NavContext = createContext(navMenuLinks);
export const CompanyNameContext = createContext(companyName);

interface LayoutProps {
  children: ReactNode;
}

export default ({ children }: LayoutProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const [data] = useState(navMenuLinks);
  useEffect(() => {
    if (data !== undefined && data?.length > 0) {
      if (!document || !document.fonts) {
        setIsLoading(false);
      } else {
        document.fonts.ready.then(() => {
          setIsLoading(false);
        });
      }
    }
  }, [data]);
  return (
    <CompanyNameContext.Provider value={companyName}>
      <NavContext.Provider value={data}>
        {isLoading && <Loader />}
        <div tabIndex={-1} className={`${isLoading ? 'container loading' : 'container'}`}>
          <Nav navMenuLinks={navMenuLinks} />
          {children}
          <Footer />
        </div>
      </NavContext.Provider>
    </CompanyNameContext.Provider>
  );
};
